import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import {Col} from "reactstrap";

class Lightbox extends Component {
    state = {
        showLightbox: false,
        selectedImage: 0,
    }

    componentDidMount = () => {
        window.addEventListener('keyup', this.handleKeyUp, false)
    }

    componentWillUnmount = () => {
        window.removeEventListener('keyup', this.handleKeyUp, false)
    }

    handleClick = (e, index) => {
        e.preventDefault()
        this.setState({showLightbox: !this.state.showLightbox, selectedImage: index})
    }

    closeModal = () => {
        this.setState({showLightbox: false})
    }

    goBack = () => {
        this.setState({selectedImage: this.state.selectedImage - 1})
    }

    goForward = () => {
        this.setState({selectedImage: this.state.selectedImage + 1})
    }

    handleKeyUp = e => {
        e.preventDefault()
        const {keyCode} = e
        if (this.state.showLightbox) {
            if (keyCode === 37) {
                // Left Arrow Key
                if (this.state.selectedImage > 0) {
                    this.setState({selectedImage: this.state.selectedImage - 1})
                }
            }
            if (keyCode === 39) {
                // Right Arrow Key
                if (this.state.selectedImage < this.props.images.length - 1) {
                    this.setState({selectedImage: this.state.selectedImage + 1})
                }
            }
            if (keyCode === 27) {
                // Escape key
                this.setState({showLightbox: false})
            }
        }
    }

    render() {
        const {images} = this.props
        const {showLightbox, selectedImage} = this.state

        return (
            <>
                <Fragment>
                    {images.map((img, i) => (
                        <Col className="d-flex" xs="12" md="4" key={i}>

                            <a className="w-100" style={{display: "block"}} href={img.node.childImageSharp.sizes.src} alt="Car Image"
                               onClick={e => this.handleClick(e, i)}>
                                <Img sizes={img.node.childImageSharp.sizes} className="rounded img-raised w-100" style={{position: "relative", minHeight: "220px"}}/>
                            </a>

                        </Col>
                    ))}

                    <LightboxModal visible={showLightbox} onKeyUp={e => this.handleKeyDown(e)}>
                        <LightboxContent>
                            <Img sizes={images[selectedImage].node.childImageSharp.sizes}/>
                            <Controls>
                                <Button onClick={this.closeModal} className="btn btn-light">Close</Button>
                                <LeftRight>
                                    <Button onClick={this.goBack} disabled={selectedImage === 0} className="btn btn-gold">
                                        Previous
                                    </Button>
                                    <Button onClick={this.goForward} disabled={selectedImage === images.length - 1} className="btn btn-gold">
                                        Next
                                    </Button>
                                </LeftRight>
                            </Controls>
                        </LightboxContent>
                    </LightboxModal>
                </Fragment>
            </>
        )
    };
}

const Gallery = styled.div``;

const GalleryItem = styled.div`
  position: relative;
`

const Button = styled.button``

const LightboxModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`
const LightboxContent = styled.div`
  margin: 15px;
  max-width: 700px;
  width: 100%;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftRight = styled.div`
  button:first-child {
    margin-right: 10px;
  }
`

Lightbox.propTypes = {
    images: PropTypes.array.isRequired,
}

export default Lightbox;
