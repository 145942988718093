import PropTypes from "prop-types"
import Img from "gatsby-image";
import {Col} from "reactstrap";
import React from "react";

const Photo = (props) => {

    let image = {};

    if (props.isSrc) {
        image = {
            src: props.photo.setSrc
        }
    } else {
        image = {
            fluid: props.photo
        }
    }

    return (
        <Col className="d-flex" xs="12" md="4">
            <Img
                alt={props.alt}
                className={props.classes}
                {...image}
                style={props.style}
            />
        </Col>
    );
};

export default Photo;

Photo.propTypes = {
    alt: PropTypes.string,
    classes: PropTypes.string,
    photo: PropTypes.object || PropTypes.string,
    style: PropTypes.object,
    isSrc: PropTypes.bool
}

Photo.defaultProps = {
    alt: 'Image',
    classes: 'rounded img-raised w-100',
    style: {position: "relative", minHeight: "220px"},
    isSrc: false,
}
